<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/daiban/check/index.vue
 * @Author: 张兴业
 * @Date: 2021-11-11 16:06:10
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-11-12 11:48:45
-->
<template>
  <div class="login">
    <div class="top_bg">
      <img src="@/assets/img/ditui/top_bg.png" alt="" class="top_bg_img" />
      <div class="center_logo_back">
        <img src="@/assets/img/ditui/logo.png" alt="" class="center_logo" />
        <div>精品通</div>
      </div>
      <div class="top_download">
        <img src="@/assets/img/ditui/logo.png" alt="" class="left_logo" />
        <div class="middle">
          <div>下载精品通APP</div>
          <div class="inviteCode_bg">
            <span class="inviteCode_text">{{ inviteCode }}</span>
            <span class="copy" @click="onCopy">复制邀请码</span>
          </div>
        </div>
        <van-button type="info" class="right" @click="goDownload">
          立即下载
        </van-button>
      </div>
    </div>
    <div v-if="phone" class="login_con">
      <van-form ref="login" @submit="onSubmit">
        <!-- 手机号 -->
        <phone-field
          v-model="phone"
          :disabled="true"
          style="margin-top: 12px;"
        />
        <!-- 验证码 -->
        <code-field
          v-if="!isPasswdLogin"
          v-model="verifyCode"
          :phone="checkPhone"
          style="margin-top: 12px;"
          @senderCode="senderCode"
          @verifyCodeChange="verifyCodeChange"
        />
        <div class="actions">
          <!-- 登录按钮 -->
          <van-button
            :loading="loading"
            block
            type="info"
            native-type="submit"
            class="login_submit"
            :disabled="disabledBtn"
          >
            确认
          </van-button>
        </div>
        <!-- <agreement v-model="agree" /> -->
      </van-form>
      <div v-if="errorMsg" class="login_error">
        {{ errorMsg }}
      </div>
    </div>
  </div>
</template>

<script>
import { verifyPhoneWithNoAreaCode } from "@/utils/pattern.js";
import clip from "@/utils/clipboard";

// const NavBar = () => import("./components/NavBar");
// const PageTitle = () => import("./components/PageTitle");
const PhoneField = () => import("../../ditui/register/components/PhoneField");
const CodeField = () => import("../../ditui/register/components/CodeField");

export default {
  components: {
    // PageTitle,
    PhoneField,
    CodeField,
    // EamilField
  },
  data() {
    return {
      inviteCode: "",
      shopName: "",
      area: "",
      address: "",
      redirect: undefined,
      otherQuery: {},
      prefix: "+86",
      phone: "",
      email: "",
      verifyCode: "",
      agree: "0",
      errorMsg: "",
      isPasswdLogin: false,
      password: "",
      path: "",
      addressData: [],
      loading: false,
    };
  },
  computed: {
    disabledBtn() {
      // console.log(verifyPhoneWithNoAreaCode(this.phone));
      if (
        verifyPhoneWithNoAreaCode(this.phone) &&
        this.verifyCode &&
        this.verifyCode.length === 6
      ) {
        return false;
      }
      return true;
    },
    checkPhone() {
      return verifyPhoneWithNoAreaCode(this.phone);
    },
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const { id } = this.$route.query;
      const url = `/api/front/push/pushClue/loadAgencyInfo/${id}`;
      this.$request(url)
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.phone = res.data.phone;
          }
        })
        .catch(() => {});
    },
    onCopy(e) {
      clip(this.$route.query.inviteCode, e);
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    // 获取微信登录code
    wechat() {
      // wechat();
    },
    prefixChange(value) {
      this.prefix = value;
    },
    // 验证码/密码登录切换
    swichLoginMethod() {
      this.isPasswdLogin = !this.isPasswdLogin;
    },
    onSubmit(values) {
      // console.log(values, this.phone, this.agree);
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (!verifyPhoneWithNoAreaCode(values.phone)) {
        this.errorMsg = "手机号码格式不正确";
        return;
      }

      this.$request("/api/front/user/checkPhoneCode", {
        method: "POST",
        data: {
          phone: this.phone,
          code: this.verifyCode,
          kind: "AgencyRegister",
        },
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.$router.push(`/daiban/confirm?id=${this.$route.query.id}`);
          }
        })
        .catch(() => {
          this.loading = false;
        });

      // const data = {
      //   storeDsName: this.shopName,
      //   phone: this.phone,
      //   code: this.verifyCode,
      //   kind: "StoreDsRegister",
      //   inviteCode: this.inviteCode,
      //   address: this.area + this.address,
      //   districtId: this.$refs.addressArea.districtId,
      //   email: this.email
      // };
      // // console.log(this.isPasswdLogin, "this.isPasswdLogin");
      // const url = "/api/front/storeDs/storeDsRegister";
      // this.$request(url, {
      //   method: "POST",
      //   data
      // })
      //   .then(res => {
      //     this.loading = false;
      //     if (res.code === 1) {
      //       this.goDownload();
      //     }
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //   });
    },
    goForgot() {
      this.$router.push("/forgot");
    },
    // 验证码
    verifyCodeChange(value) {
      this.verifyCode = value;
    },
    // 发送验证码
    senderCode(callback) {
      if (this.sending) {
        return;
      }
      const data = {
        phone: this.phone,
        kind: "AgencyRegister",
      };
      const url = "/api/front/user/sendSms";
      this.sending = true;
      this.$request(url, {
        method: "POST",
        data,
      })
        .then((res) => {
          this.sending = false;
          if (res.code === 1) {
            this.$message({
              message: "验证码已发送到您的手机",
              type: "success",
            });
            callback();
          }
        })
        .catch(() => {
          this.sending = false;
        });
    },
    goDownload() {
      this.$router.push("/download/app/distributor");
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  overflow: auto;

  .login_close {
    width: 24px;
    margin: 11px 0 0 24px;
  }
  .login_title {
    margin-top: 30px;
    line-height: 33px;
  }

  .top_bg {
    position: relative;
    .top_bg_img {
      width: 100%;
    }

    .top_download {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 42px;
      padding: 12px;
      display: flex;
      color: white;

      img {
        width: 42px;
        height: 42px;
      }

      .middle {
        margin: auto 12px;
        width: calc(100% - 154px);
        font-size: 12px;
        line-height: 21px;

        .inviteCode_bg {
          display: flex;
          .inviteCode_text {
            display: block;
            max-width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: auto 0;
          }
        }

        .copy {
          border: 1px solid #ffffff;
          border-radius: 12px;
          padding: 2px 5px;
          margin-left: 8px;
          cursor: pointer;
          word-break: normal;
        }
      }

      .right {
        margin: auto 0;
        width: 88px;
        background: white;
        border: none;
        color: black;
        height: 32px;
        border-radius: 16px;
        font-size: 13px;
        font-weight: normal;
      }
    }

    .center_logo_back {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 70px;
      height: 70px;
      transform: translate(-50%, -50%);
      font-size: 14px;
      text-align: center;

      .center_logo {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        width: 70px;
        height: 70px;
        margin-bottom: 5px;
        // transform: translate(-50%, -50%);
      }
    }
  }

  .login_con {
    margin: 0 38px 72px 38px;
  }

  .actions {
    margin-top: 12px;

    .login_submit {
      background: linear-gradient(135deg, #434343 100%, #000000 100%);
      border: none;
      font-size: 14px;
      height: 48px;
      line-height: 48px;
      border-radius: 13px;
      font-weight: 500;
    }
  }

  .login_error {
    padding: 4px;
    color: #ff0000;
    font-size: 12px;
    text-align: center;
  }

  .login_switch {
    padding: 10px 0;
    font-size: 12px;
    font-weight: 400;

    span {
      cursor: pointer;
    }

    .forgot {
      float: right;
    }
  }

  .wechat_con {
    margin-top: 80px;
  }

  .van-cell {
    padding: 10px 0;
  }
}
</style>
